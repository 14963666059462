/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@4.3.1/dist/js/bootstrap.bundle.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/jquery-lazy@1.7.11/jquery.lazy.min.js
 * - /npm/jquery-lazy@1.7.11/jquery.lazy.plugins.min.js
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/swiper@4.5.1/dist/js/swiper.min.js
 * - /npm/malihu-custom-scrollbar-plugin@3.1.5/jquery.mCustomScrollbar.concat.min.js
 * - /npm/inputmask@4.0.9/dist/inputmask/dependencyLibs/inputmask.dependencyLib.min.js
 * - /npm/inputmask@4.0.9/dist/inputmask/inputmask.min.js
 * - /npm/hc-sticky@2.2.7/dist/hc-sticky.min.js
 * - /npm/flatpickr@4.5.7/dist/flatpickr.min.js
 * - /npm/flatpickr@4.5.7/dist/l10n/ru.min.js
 * - /npm/flatpickr@4.5.7/dist/plugins/rangePlugin.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
